<template>
  <CCard>
    <CCardHeader>
      <h2>Task Definition: {{ task ? task.title : '' }}</h2>
    </CCardHeader>
    <CCardBody>
      <CDataTable :items="taskRefs" :fields="definitionFields" />
    </CCardBody>
  </CCard>
</template>
<script>
import TASK_REFS from '@/../static/data/workflow_def_onb.json';
import WORKFLOWS from '@/../static/data/workflows.json';

const DEF_FIELDS = [
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'ref',
    label: 'Ref',
  },
  {
    key: 'title',
    label: 'Title',
  },
];

export default {
  name: 'TaskDefinition',
  props: {
    workflowref: {
      type: String,
      required: true,
    },
  },
  computed: {
    taskRefs() {
      return TASK_REFS;
    },
    definitionFields() {
      return DEF_FIELDS;
    },
    workflow() {
      return WORKFLOWS.find((w) => w.ref === this.workflowref);
    },
  },
};
</script>
